<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col
        cols="12"
        class="col-16 pl-sm-32 py-24 py-sm-32 overflow-hidden"
    >
      <h2>Pasien Edit</h2>
    </b-col>
    <b-col class=" mb-3 ">
      <b-row>
        <b-col cols="5">
          <b-card class="custom-card" >
            <b-card-header class="bg-card-head" >
              Patient
            </b-card-header>
            <b-card-body>
              <b-card class="custom-card-b">
                <b-button variant="primary-2" >
                  <i class="ri-add-line" /> Choose File
                </b-button>
              </b-card>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="7">
          <b-card class="custom-card">
            <b-row class="mb-3">
              <b-col cols="6">
                <label >Nama Pasien</label>
                <b-form-input
                  v-model="patientData.nama"
                  type="text"
                  placeholder="Masukkan Nama Pasien"
                />
                <!-- <b-form-invalid-feedback :state="v$.patientData.nama.$dirty ? !v$.patientData.nama.$error : null">
                  {{ errorMessages.nama }}
                </b-form-invalid-feedback> -->
              </b-col>
              <b-col cols="6">
                <label>Tanggal Lahir</label>
                <b-form-input
                  v-model="patientData.tanggalLahir"
                  type="date"
                  
                  placeholder="Tanggal Lahir"
                />
                <!-- <b-form-invalid-feedback :state="v$.patientData.tanggalLahir.$dirty ? !v$.patientData.tanggalLahir.$error : null">
                  {{ errorMessages.tanggalLahir }}
                </b-form-invalid-feedback> -->
              </b-col>
            </b-row>
            <b-row>
              <b-col class="d-flex  mb-3">
                <b-button 
                  variant="danger-1 mx-2" 
                  @click="$router.go(-1)"
                >
                  Batal
                </b-button>
                <b-button
                    variant="success-1 "
                    type="submit"
                    :loading="isBusy"
                    @click="updatePatient"
                >
                  <b-spinner 
                    small 
                    label="Small Spinner" 
                    v-if="isBusy"
                  />
                  <span v-else>Simpan</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row >
        <b-col cols="5">
          <b-card class="custom-card">
            <b-row class="mb-3">
              <b-col cols="5">
                <label >Gol Darah</label>
                <b-form-select
                  v-model="patientData.golDarah" :options="options" :selected="selected"
                  
                  placeholder="A+"
                />
                <!-- <b-form-invalid-feedback :state="v$.patientData.golDarah.$dirty ? !v$.patientData.golDarah.$error : null">
                  {{ errorMessages.golDarah }}
                </b-form-invalid-feedback> -->
              </b-col>
              <b-col cols="6">
                <label>Rhesus</label>
                <b-form-select
                  v-model="patientData.rhesus" :options="optionsReus"
                  
                  placeholder="(+) Positif"
                />
                <!-- <b-form-invalid-feedback :state="v$.patientData.tanggalLahir.$dirty ? !v$.patientData.tanggalLahir.$error : null">
                  {{ errorMessages.tanggalLahir }}
                </b-form-invalid-feedback> -->
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { required, minLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import {
  BButton, 
  BCol, 
  BCard,
  BForm, 
  BFormGroup, 
  BFormInput, 
  BFormTextarea, 
  BRow, 
  BSpinner,
  BFormInvalidFeedback,
  BFormSelect,
} from 'bootstrap-vue';
import fieldMappings from './data/fieldMappings'

export default {
  setup: () => ({ v$: useVuelidate() }),
  name: 'edit-patient',
  components: {
    BSpinner, 
    BForm, 
    BButton, 
    BCol,
    BCard,
    BRow, 
    BFormGroup, 
    BFormInput, 
    BFormTextarea,
    BFormInvalidFeedback,
    BFormSelect,
  },
  data() {
    return {
      patientData: {
        nama: '',
        tanggalLahir: '',
        golDarah: 1,
        rhesus: 'positif',
      },
      errorMessages: {},
      options: [
        {value: 1, text: "A+"},
        {value: 2, text: "A-"},
        {value: 3, text: "B+"},
        {value: 4, text: "B-"},
        {value: 5, text: "AB+"},
        {value: 6, text: "AB-"},
        {value: 7, text: "O"},
      ],
      optionsReus: [
        {value: "positif", text: "(+) Positif"},
        {value: "negatif", text: "(-) Negatif"},
      ],
    }
  },
  validations() {
    return {
      patientData: {
        nama: { required, minLength: minLength(3) },
        tanggalLahir: { required },
        golDarah: { required },
        rhesus: { required },
      },
    }
  },
  computed: {
    isBusy() {
     return this.$store.state.patientStore.isBusy
    },
    patient() {
      return this.$store.state.patientStore.patient;
    }
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('patientStore/getDetailPatient', this.$route.params.id)

      this.patientData.nama = this.patient.name;
      this.patientData.tanggalLahir = this.patient.birth_date;
      this.patientData.golDarah = this.patient.blood_id;
      this.patientData.rhesus = this.patient.rhesus;
    },
    async updatePatient() {
      let formData = new FormData();

      formData.append('id', this.$route.params.id)
      formData.append('name', this.patientData.nama)
      formData.append('birth_date', this.patientData.tanggalLahir)
      formData.append('blood_id', this.patientData.golDarah)
      formData.append('rhesus', this.patientData.rhesus)

      await this.$store.dispatch('patientStore/updatePatient', formData)
      .then(() => {
        this.$router.push({ name: 'listpatients' })
        this.$bvToast.toast('Data Pasien Berhasil Di Edit', {
          title: 'Success',
          variant: 'success',
          solid: true,
        })
      }).catch(err => {
          if (err.response && err.response.data && err.response.data.success === false && err.response.data.message) {
            const errorMessage = err.response.data.message
            Object.keys(this.patientData).forEach(field => {
              if (this.v$.patientData[field]) {
                this.v$.patientData[field].$error = true
                this.v$.patientData[field].$touch()
                this.$set(this.errorMessages, field, fieldMappings[field], errorMessage)
              }
            })
          }
        })
    },
  },
  created() {
    this.fetchData();
  },
}
</script>

<style scoped>
.bg-card-head {
  background-color: #FF455E !important;
  color: antiquewhite;
  font-size: 20
}
.custom-card {
  border-radius: 0;
}.custom-card-b {
  border-radius: 0;
  align-items: center;
}
</style>